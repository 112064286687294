import React from "react"
import ReactDOM from 'react-dom';
import App from "./App"
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom"
import { GoogleOAuthProvider } from '@react-oauth/google';

ReactDOM.render(
    <GoogleOAuthProvider clientId="895607141749-k2pdlsf05hgjbapofkivfbq9ht24acf9.apps.googleusercontent.com">
        <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.StrictMode>
    </GoogleOAuthProvider>, document.getElementById('root'));

serviceWorker.unregister();