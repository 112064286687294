import React from 'react';
import Page from '../../components/Page';
import { makeStyles } from '@mui/styles';
import Plans from './components/Plans';
import AuthService from '../../services/auth.service';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: '100%',
  },
  paper: {
    paddingTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
  }
}));

const PricingView = () => {
  const classes = useStyles();
  const currentUser = AuthService.getCurrentUser();

  return (
    <Page
      title="Visible Stars"
      className={classes.root}
    >
      <Plans label={!!currentUser && currentUser.email ? 'Select' : 'Sign Up'} />
    </Page >
  );
};
export default PricingView;
