import { colors } from '@mui/material';

const white = '#FFFFFF';
const black = '#000000';

const palette = {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#0000c6',
    main: '#0053a0',
    light: "#00b2ff"
  },
  secondary: {
    contrastText: white,
    dark: '#00b2ff',
    main: '#00b2ff',
    light: "#00b2ff"
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600]
  },
  link: '#00b2ff',
  icon: colors.blueGrey[600],
  background: {
    default: 'transparent',
    paper: white,
    light: colors.grey[100]
  },
  divider: colors.grey[200]
};

export default palette;