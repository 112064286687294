import React, {useContext} from 'react';
import {makeStyles} from '@mui/styles';
import {SurveyContext} from '../context';
import {
    Typography,
    Button,
    Box,
} from '@mui/material';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        margin: theme.spacing(0, 3)
    }
}));

const Toolbar = () => {
    const classes = useStyles();
    const surveyContext = useContext(SurveyContext);
    const { selectedSurvey } = surveyContext;
    return (
        <Box
            className={classes.root}
            display="flex"
            flexDirection="row"
            justifyContent='space-between'
            alignItems="center"
        >
            <Box mt={3}>
                <Typography variant="h2">{selectedSurvey.id !== -1 ? selectedSurvey.name : 'Untitled' }</Typography>
            </Box>
            <Box mt={3}
                display="flex"
                justifyContent="flex-end"
                flexDirection="row"
            >
                <Box p={1}>
                    <Button
                        size='small'
                        style={{ textTransform: 'none',color:"#fff", backgroundColor: "#6B787F",
                            "&:hover": {
                            cursor: "pointer",
                            backgroundColor: "#6B787F"
                            },
                        }}
                        //onClick={() => submitForReview(selectedSurvey.id)}
                    >
                        <ChatBubbleOutlineOutlinedIcon style={{padding: '2px'}} fontSize='medium' />
                    </Button>
                </Box>
                <Box p={1}>
                    <Button
                        size='small'
                        style={{ textTransform: 'none', marginRight: '10px', backgroundColor: "#6B787F",
                            "&:hover": {
                            cursor: "pointer",
                            backgroundColor: "#6B787F"
                            },
                        }}
                        // component={RouterLink}
                        // to={`${process.env.PUBLIC_URL}/app/create`}
                        variant="contained"
            
                    //   onClick={() => approveSurvey(selectedSurvey.id)}
                    >
                    <PersonAddAltOutlinedIcon style={{ marginRight: '12px', padding: '2px'}} /> 
                    <Typography color="#fff" variant="h5">Add collaborators</Typography>
                    
                    </Button>
                </Box>
            </Box>
        </Box>
    );
  };
export default Toolbar;
