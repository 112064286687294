import React, { createContext, useCallback, useEffect} from "react";
import PropTypes from "prop-types";

const surveysList = [
  {
    id: 1,
    status: 'DRAFT',
    name: 'Customer Satisfaction Survey Template',
    updatedOn: '08/08/2024',
    stats: 
      {
        questionsCount: 0
      }
  },
  {
    id: 2,
    status: 'OPEN',
    name: 'Credit card',
    updatedOn: '03/07/2024',
    stats: 
      {
        questionsCount: 20,
        responsesTotalCount: 100,
        responsesCount: 10
      }
  },
  {
    id: 3,
    status: 'DRAFT',
    name: 'bank account open',
    updatedOn: '01/07/2024',
    stats: 
      {
        questionsCount: 20,
        issues: 0,
      }
  },
  {
    id: 4,
    status: 'OPEN',
    name: 'Copy of Customer Satisfaction Survey Template',
    updatedOn: '03/07/2024',
    stats: 
      {
        questionsCount: 20,
        responsesTotalCount: 100,
        responsesCount: 0
      }
  }
];

export const Context = createContext({});

export const Provider = props => {
    // Initial values are obtained from the props
    const {
        surveys: initialSurveys,
        children
    } = props;

    // Use State to keep the values
    const [surveys, setSurveys] = React.useState(initialSurveys);

    const loadSurveys = useCallback(() => {
      setSurveys(surveysList);
    }, []);

    useEffect(() => {
      loadSurveys()
    }, [loadSurveys]);

    // Make the context object:
    const surveyContext = {
      surveys,
      surveysList
    };

    // pass the value in provider and return
    return <Context.Provider value={surveyContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

Provider.propTypes = {
  surveys: PropTypes.array
}

Provider.defaultProps = {
  surveys: []
};
