import AuthService from '../services/auth.service';

export const handleError = (error) => {
    console.log("handleError: " + JSON.stringify(error));
    if (error.response && [401, 403].indexOf(error.response.status) !== -1) {
        // logout if 401 Unauthorized or 403 Forbidden response returned from api
        localStorage.setItem('authorized', false);
        AuthService.logout();
        window.location.reload(true);
    }
    const errorMessage = (error.response && error.response.data.message) || error.message || error.statusText;
    return Promise.reject(errorMessage);
}
