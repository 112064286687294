import React, {useContext} from 'react';
import Page from '../../../components/Page';
import {makeStyles} from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';

import {
    Grid,
    Typography,
    CardContent,
    Card,
} from '@mui/material';
import {SurveyContext} from '../context';
import EditNoteIcon from '@mui/icons-material/EditNote';
import RouteIcon from '@mui/icons-material/Route';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import SurveyListView from './SurveyListView';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2)
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: theme.spacing(20),
            marginTop: theme.spacing(2)
        }
        
    },
    section: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(1, 3)
        }
    },
    card: {
        textAlign: 'left',
        padding: '4px 0 0 10px',
        "&:hover": {
            backgroundColor: "#fff"
        }
    }
}));

const Home = () => {
    const classes = useStyles();
    const surveyContext = useContext(SurveyContext);
    const {surveys} = surveyContext;
    if (!!surveys && surveys.length > 0) {
        return (
            <SurveyListView />
        )
    }
    const list = [
        {   
            id: 1,
            title: 'Start from scratch',
            subtitle: 'Begin with a blank page, or copy and paste a survey you’ve written.',
            location: '/app/surveys/edit?id=-1'
        },
        {   
            id: 2,
            title: 'Use a quick template',
            subtitle: 'Use a template we’ve picked for you to create and send surveys faster.',
            location: '/app/surveys/templates'
        },
        {   
            id: 3,
            title: 'Build with AI',
            subtitle: 'Type a short prompt. AI will create a tailored survey just for you.',
            location: '/app/surveys/AI'
        }
    ]
    return (
        <Page title="Survey" className={classes.root}>
            <Grid container spacing={3} className={classes.section}>
                <Grid item mt={3} xs={12} md={12}>
                    <Typography gutterBottom variant='h2'>What do you want to do?</Typography>
                </Grid>
                {list.map((l, idx) => (
                <Grid key={idx} component={RouterLink} to={l.location} item xs={12} md={3}>
                    <Card className= {classes.card} sx={{
                        border: '2px solid transparent',
                        cursor: 'pointer',
                        boxShadow: 'rgb(237, 238, 238) 0px 0px 0px 2px',
                        transition: 'border-color 0.2s, box-shadow 0.2s',
                        borderRadius: '24px',
                        textDecoration: 'none',
                        backgroundColor: '#FFFFFF',
                        }}>
                        <CardContent>
                            <Grid container>
                                <Grid item my={1} xs={12} md={12}>
                                    { l.id === 1 ? <EditNoteIcon sx={{ fontSize:'55px'}} /> : l.id ===2 ? <RouteIcon sx={{ fontSize:'55px'}} /> : <AutoFixHighOutlinedIcon sx={{ fontSize:'55px'}} />}
                                </Grid>
                                <Grid item my={1} xs={12} md={12}>
                                    <Typography fontWeight={'600'} gutterBottom variant='subtitle1'>{l.title}</Typography>
                                </Grid>
                                <Grid item my={1} xs={12} md={12}>
                                    <Typography fontSize={'15px'} gutterBottom variant='body1'>{l.subtitle}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                    ))}
            </Grid>
        </Page >
    );
};
export default Home;
