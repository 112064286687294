import React, {useContext} from 'react';
import {
  Box,
  Tabs,
  Tab,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import LooksOneRoundedIcon from '@mui/icons-material/LooksOneRounded';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3RoundedIcon from '@mui/icons-material/Looks3Rounded';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useMediaQuery } from "@mui/material";
import theme from '../../../theme';
import { CheckOutContext } from '../context';
import Plan from '../components/Plan';
import Billing from './Billing';
import Review from './Review';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    height: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1)
  },
  topbar: {
    padding: theme.spacing(0,5)
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  header: {
    borderBottom: '1px solid #EDEEEE',
    boxShadow: 'rgba(0, 0, 0, 0.14) 0px 4px 4px 0px',
  },
  content: {
    padding: theme.spacing(0, 2)
  }
}));

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-flexContainer': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent:'center',
    alignItems: 'center'
  },
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    // maxWidth: 40,
    width: '100%',
    backgroundColor: '#635ee7',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    minHeight: "60px", 
    minWidth: "50px",
    fontSize: theme.typography.h5.fontSize,
    marginRight: theme.spacing(1),
    color: 'primary',
    '&.Mui-selected': {
      color: 'primary',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }),
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const CheckOut = () => {
  const classes = useStyles();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const checkOutContext = useContext(CheckOutContext);
  const { tabValue, handleTabChange} = checkOutContext;

  return (
    <>
    <StyledTabs
      m={2}
      value={tabValue}
      onChange={handleTabChange}
      className={classes.header}
    >
      <StyledTab icon={<LooksOneRoundedIcon color='primary' />} iconPosition="start" label= {!mobileDevice || tabValue === 0 ? "Choose plan" : ''} />
      <StyledTab sx={{minWidth: 0, padding: 0}} label="" icon={<ArrowForwardIosIcon fontSize='small' />} disabled />
      <StyledTab icon={<LooksTwoIcon color={tabValue === 0 ? '' : 'primary'} />} disabled={tabValue === 0} iconPosition="start" label= {!mobileDevice || tabValue === 2 ? "Enter billing details": ''} />
      <StyledTab sx={{minWidth: 0, padding: 0}} label="" icon={<ArrowForwardIosIcon fontSize='small' />} disabled />
      <StyledTab icon={<Looks3RoundedIcon color={tabValue === 2 || tabValue === 0 ? '' : 'primary'} />} disabled ={tabValue === 2 || tabValue === 0} iconPosition="start" label={!mobileDevice || tabValue === 4 ? "Review and pay" : ''} />
    </StyledTabs>
    <Box mt={2}>
      <TabPanel value={tabValue} index={0}>
        <Plan />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Billing/>
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <Review />
      </TabPanel>
    </Box>
    </>
  );
};

export default CheckOut;
