import React from 'react';
import Page from '../../components/Page';
import {makeStyles} from '@mui/styles';
import { SurveyContextProvider } from "./context";
import Survey from './components/Survey';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
    }
}));

const CreateSurveyView = () => {
    const classes = useStyles();
    const { id } = useParams();
    const surveyId = id ? id : -1;
    console.log('surveyId: ' + surveyId);
    return (
        <Page title="Create Survey" className={classes.root}>
            <SurveyContextProvider surveyId={surveyId}>
                <Survey />
            </SurveyContextProvider>
        </Page >
    );
};
export default CreateSurveyView;
