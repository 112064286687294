// import Navbar from "./Navbar"
// import Pricing from "./views/Pricing"
// import Home from "./views/Home"
// import About from "./views/About"
// import { Route, Routes } from "react-router-dom"

// function App() {
//   return (
//     <>
//       {/* <Navbar /> */}
//       <div className="container">
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/pricing" element={<Pricing />} />
//           <Route path="/about" element={<About />} />
//         </Routes>
//       </div>
//     </>
//   )
// }

// export default App
import React from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './routes';
import withRoot from './withRoot';
import { withStyles } from "@mui/styles";
import { createStyles, makeStyles } from '@mui/styles';

const styles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      backgroundColor: '#FFFFFF',
      height: '100%',
      width: '100%'
    },
    a: {
      textDecoration: 'none'
    },
    '#root': {
      height: '100%',
      width: '100%'
    }
  }
}));


const App = () => {
  const routing = useRoutes(routes, `${process.env.PUBLIC_URL}`);
  return (
    <>
      {routing}
    </>
  );
};

export default withRoot(
  withStyles(styles, { withTheme: true })(App)
);
