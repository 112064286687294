import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';


function MuiAlert(props) {
    return <Alert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

const CustomizedSnackbar = props => {
    const { open, handleClose, severity, msg, autoHideDuration } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
                <MuiAlert onClose={handleClose} severity={severity}>
                    {msg}
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

CustomizedSnackbar.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    severity: PropTypes.string.isRequired,
    msg: PropTypes.string.isRequired
};

CustomizedSnackbar.defaultProps = {
    open: true,
    severity: 'success',
    msg: 'Success'

};

export default CustomizedSnackbar;

