import React from 'react';
import Page from '../../../components/Page';
import {makeStyles} from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import {
    Grid,
    Typography,
    CardContent,
    Card,
} from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import PeopleIcon from '@mui/icons-material/People';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2)
        },
        [theme.breakpoints.up('lg')]: {
            margin: theme.spacing(0, 35, 20, 35),
        }
        
    },
    section: {
        textAlign: 'left',
        display: 'flex',
        justifyContent: 'left',
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(1, 3)
        }
    },
    card: {
        textAlign: 'left',
        padding: '4px 0 0 10px',
        "&:hover": {
            backgroundColor: "#fff"
        }
    }
}));

const getIcon = (stats) => {
    var result = <EditNoteIcon sx={{ fontSize:'55px'}} />;
    switch (stats) {
      case 1:
        result = <EditNoteIcon sx={{ fontSize:'55px'}} />;
        break;
      case 2:
        result = <ContentCopyIcon sx={{ fontSize:'55px'}} />;
        break;
      case 3:
        result = <AutoFixHighOutlinedIcon sx={{ fontSize:'55px'}} />;
        break;
      case 4:
        result = <ListAltIcon sx={{ fontSize:'55px'}} />;
        break;
      case 5:
        result = <ContentPasteIcon sx={{ fontSize:'55px'}} />;
        break;
      case 6:
        result = <PeopleIcon sx={{ fontSize:'55px'}} />;
        break;
      default:
        result = <PublishedWithChangesIcon sx={{ fontSize:'55px'}} />;
        break;
    }
    return result;
}

const CreateView = () => {
    const classes = useStyles();
    const list = [
        {   
            id: 1,
            title: 'Start from scratch',
            subtitle: 'Begin with a blank survey or form. Then add your questions, text, and images.',
            location: '/app/surveys/edit?id=-1'
        },
        {   
            id: 2,
            title: 'Copy an existing survey',
            subtitle: 'Choose a survey. Make a copy. Edit as needed.',
            location: '/app/surveys/templates'
        },
        {   
            id: 3,
            title: 'Build with AI',
            subtitle: 'Type a short prompt. AI will create a tailored survey just for you.',
            location: '/app/surveys/AI'
        },
        {   
            id: 4,
            title: 'Pick a popular template',
            subtitle: 'Ask the right questions and save time with a template built for your situation.',
            location: '/app/surveys/templates'
        },
        {
            id: 5,
            title: 'Paste your content',
            subtitle: 'Add all your questions and answers at once. Or copy and paste one at a time.',
            location: '/app/surveys/paste'
        },
        {
            id: 6,
            title: 'Build a target audience',
            subtitle: 'Choose audience demographics. Then build a survey when you’ve ready.',
            location: '/app/surveys/paste'
        },
        {
            id: 7,
            title: 'Use proven research methods',
            subtitle: 'Launch studies with target audiences about product, marketing, and brand.',
            location: '/app/surveys/paste'
        }
    ]
    return (
        <Page title="Create Survey" className={classes.root}>
            <Grid container spacing={3} className={classes.section}>
                <Grid item mt={3} xs={12} md={12}>
                    <Typography gutterBottom variant='h2'>Choose a starting point</Typography>
                </Grid>
                {list.map((l, idx) => (
                <Grid key={idx} component={RouterLink} to={l.location} item xs={12} md={6}>
                    <Card className= {classes.card} sx={{
                        border: '2px solid transparent',
                        cursor: 'pointer',
                        boxShadow: 'rgb(237, 238, 238) 0px 0px 0px 2px',
                        transition: 'border-color 0.2s, box-shadow 0.2s',
                        borderRadius: '24px',
                        textDecoration: 'none',
                        backgroundColor: '#FFFFFF',
                        }}>
                        <CardContent>
                            <Grid container>
                                <Grid item my={1} xs={12} md={12}>
                                    {getIcon(l.id)}
                                </Grid>
                                <Grid item my={1} xs={12} md={12}>
                                    <Typography fontWeight={'600'} gutterBottom variant='subtitle1'>{l.title}</Typography>
                                </Grid>
                                <Grid item my={1} xs={12} md={12}>
                                    <Typography fontSize={'15px'} gutterBottom variant='body1'>{l.subtitle}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                    ))}
            </Grid>
        </Page >
    );
};
export default CreateView;
