import * as React from 'react';
import { useState, forwardRef, useEffect} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AuthService from '../../services/auth.service';
import { makeStyles, styled } from '@mui/styles';
import Logo from '../../components/Logo';
import { Card, CardHeader, Grid, ClickAwayListener, Drawer, Tabs, Tab, ListItem, Divider } from '@mui/material';
import theme from '../../theme';
import Popper from '@mui/material/Popper';

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));


const openedMixin = (theme) => ({
  width: '50%',
  top: 57,
  height: 'calc(100% - 64px)',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const MDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexShrink: 0,
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
  }),
);

const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: 'rgba(0,0,0,.1)'
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 16,
    width: 16
  },
  popover: {
    width: '100%',
    backgroundColor: '#fff',
    padding: theme.spacing(4,4),
    border: '1px solid rgba(0,0,0,.1)'
  },
  menuItem: {
    borderRadius: '25px', 
    backgroundColor: '#f7f7f7', 
    border: '2px solid #f7f7f7',
    '&:hover': {
      backgroundColor: '#cae8ee', 
    }
  },
  menuItems: {
    borderRadius: '25px', 
    backgroundColor: '#fff', 
    border: '2px solid #fff',
    '&:hover': {
      backgroundColor: '#cae8ee', 
    }
  },
  card: {
    border: 'none',
    height: '100%',
    width: '100%',
    textAlign: 'left'
  },
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  title: {
    marginRight: 'auto'
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  }
}));

function ResponsiveAppBar() {
  const classes = useStyles();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [open, setOpen] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const currentUser = AuthService.getCurrentUser();
  const [tabValue, setTabValue] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = React.useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleTabChange = (event, newValue) => {
    console.log(newValue)
    setTabValue(newValue);
  };

  const handleToggle = (event, key) => {
    setOpen({[key]: !open[key]});
    if(!['Pricing', 'Enterprise'].includes(key)) setSelectedMenu(key);
    setAnchorEl(event.currentTarget)
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    AuthService.logout();
  }

  const pages = [
    {
      name: 'Products',
      submenu: [{
        name: 'x',
        url: ''
      }]
    },
    {
      name: 'Templates',
      submenu: [{
        name: 'tt',
        url: ''
      }]
    },
    {
      name: 'Pricing',
      url: `${process.env.PUBLIC_URL}/pricing`
    },
    {
      name: 'Enterprise',
      url: ''
    }, 
    {
      name: 'Research',
      submenu: [{
        name: 'rr',
        url: ''
      }]
    }
  ];
  const settings = [{name: 'Logout', function: handleLogout, url: `${process.env.PUBLIC_URL}/` }];

  const location = useLocation();

  useEffect(() => {
    if(location.pathname === '/pricing'){
      setValue(1)
    }
  }, [location.pathname]);

  const Content = () => {
    return (
      <Grid container px={2}>
        <Grid item xs={12} md={6}>
          <Grid container my={2}>
            <Grid item xs={12} md={12} className={classes.menuItem}>
                <Card
                  component={RouterLink}
                  to={`${process.env.PUBLIC_URL}/`}
                  sx={{boxShadow: 'unset'}} className={classes.card}>
                  <CardHeader
                    sx={{alignItems: 'flex-start'}}
                    avatar={
                      <Box
                        sx={{width: '30px'}}
                        component="img"
                        alt="VS"
                        src="../../favicon.ico"
                      />
                    }
                    title={
                      <Typography gutterBottom variant='h5'>
                        Product Overview
                      </Typography>
                    }
                    subheader={
                      <Typography variant='h6'>
                        VisibleStars is built to handle every use case and need. Explore our product to learn how VisibleStars can work for you.
                      </Typography>
                    }
                  />
                </Card>
            </Grid>
            <Grid item>
              <Grid container my={2}>
                <Grid item xs={12} md={6} className={classes.menuItems}>
                <Card
                  component={RouterLink}
                  to={`${process.env.PUBLIC_URL}/`}
                  sx={{boxShadow: 'unset'}} className={classes.card}>
                    <CardHeader
                      sx={{alignItems: 'flex-start'}}
                      avatar={
                        <Box
                          sx={{width: '30px'}}
                          component="img"
                          alt="VS"
                          src="../../favicon.ico"
                        />
                      }
                      title={
                        <Typography gutterBottom variant='h5'>
                          VisibleStars
                        </Typography>
                      }
                      subheader={
                        <Typography variant='h6'>
                          Get data-driven insights from a global leader in online surveys. 
                        </Typography>
                      }
                    />
                  </Card>
                </Grid>
                <Grid item xs={12} md={6} className={classes.menuItems}>
                  <Card component={RouterLink}
                  to={`${process.env.PUBLIC_URL}/`} 
                  sx={{boxShadow: 'unset'}} className={classes.card}>
                    <CardHeader
                      sx={{alignItems: 'flex-start'}}
                      avatar={
                        <Box
                          sx={{width: '30px'}}
                          component="img"
                          alt="VS"
                          src="../../favicon.ico"
                        />
                      }
                      title={
                        <Typography gutterBottom variant='h5'>
                          Integrations
                        </Typography>
                      }
                      subheader={
                        <Typography variant='h6'>
                          Integrate with 100+ apps and plug-ins to get more done.
                        </Typography>
                      }
                    />
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  let colorCode = (!!currentUser) ? '#333E48' : (location.pathname.toString() === '/pricing')? '#0053a0' : '#fff', height= '50em';
  let customTheme = (!!currentUser) ? '#fff' : (location.pathname.toString() === '/pricing')? '#fff' : '#0053a0';

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      // maxWidth: 40,
      width: '100%',
      backgroundColor: customTheme,
    },
  });
  
  const StyledTab = (props) => (<Tab 
    sx={{textTransform: 'none', opacity:1, color: customTheme, fontSize: theme.typography.h5.fontSize, fontWeight: theme.typography.h5.fontWeight,
      marginRight: theme.spacing(1),
      '&.Mui-selected': {
        color: customTheme,
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
      },
    }} 
    component={RouterLink}
    to={props.url}
    disableRipple {...props} />)

  return (
    <AppBar
      style={{ background: colorCode,  position:"fixed", zIndex: theme.zIndex.drawer + 1,
      boxShadow: 'rgba(0, 0, 0, 0.14) 0px 8px 8px -4px'
    }}
      className={classes.root}
      elevation={0}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={() => setAnchorElNav(!anchorElNav)}
              sx={{  color: customTheme }}
            >
              <MenuIcon />
            </IconButton>
            <MDrawer
              anchor="left"
              onClose={() => setAnchorElNav(!anchorElNav)}
              open={anchorElNav}
              variant="persistent"
            >
              <ListItem
                className={classes.item}
                disableGutters
              >
                <Button
                style={{color: theme.palette.text.secondary,
                  fontWeight: theme.typography.fontWeightMedium,
                  justifyContent: 'flex-start',
                  letterSpacing: 0,
                  padding: '10px 8px',
                  textTransform: 'none',
                  width: '100%'}}
                activeClassName={classes.active}
                className={classes.button}
                component={RouterLink}
                to={`${process.env.PUBLIC_URL}/`}
                >
                  <span className={classes.title}>
                    {'Home'}
                  </span>
                </Button>
              </ListItem>
              <Divider />
              <ListItem
                disableGutters
              >
                <Button
                style={{color: theme.palette.text.secondary,
                  fontWeight: theme.typography.fontWeightMedium,
                  justifyContent: 'flex-start',
                  letterSpacing: 0,
                  padding: '10px 8px',
                  textTransform: 'none',
                  width: '100%'}}
                activeClassName={classes.active}
                className={classes.button}
                component={RouterLink}
                to={`${process.env.PUBLIC_URL}/pricing`}
                >
                  <span className={classes.title}>
                    {'Plans and Pricing'}
                  </span>
                </Button>
              </ListItem>
            </MDrawer>
          </Box>

          <RouterLink to={`${process.env.PUBLIC_URL}/`}>
            <Logo
              type={(!!currentUser || location.pathname.toString() === '/pricing') ? 'white' : 'blue'}
              height={height}
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                color: 'inherit',
                textDecoration: 'none',
              }}
            />
          </RouterLink>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} />
          {!currentUser && <Box sx={{display: { xs: 'flex', md: 'none' }}} flexDirection='row' alignContent='center' justifyContent='space-between'>
          <Button
            style={{ textTransform: 'none', borderColor: customTheme, color: customTheme, backgroundColor: colorCode}}
            component={RouterLink}
            to={`${process.env.PUBLIC_URL}/login?checkout=false`}
            variant="text"
          >
            <Typography color={customTheme} variant="h5">
            {'Log in'}
            </Typography>
          </Button>
          <Button
            style={{ textTransform: 'none', borderColor: customTheme, color: customTheme, backgroundColor: colorCode}}
            component={RouterLink}
            to={`${process.env.PUBLIC_URL}/signUp?checkout=false`}
            variant="outlined"
          >
            <Typography color={customTheme} variant="h5">
            {'Sign Up Free'}
            </Typography>
          </Button>
        </Box>}
          {!!currentUser &&<Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
            <Box display='flex' flexDirection='row' alignContent='center' justifyContent='space-between'>
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="Remy Sharp" src='' sx={{  color: "#fff" }}>{currentUser ? currentUser.name.charAt(0) : ''}</Avatar>
            </IconButton>
            <Menu
              sx={{ mt: '48px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((s,i) => (
                <MenuItem key={s.name} component={CustomRouterLink} to={s.url} onClick={s.function}>
                  <Typography textAlign="center">{s.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          </Box>}
        {!currentUser && <ClickAwayListener onClickAway={(e) => handleToggle(e, selectedMenu)}>
          <Box sx={{ flexGrow: 1, ml:10, alignSelf: 'flex-end', display: { xs: 'none', md: 'flex' } }}>
            <StyledTabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {pages.map((page) => (
                <StyledTab onClick={(e) => handleToggle(e, page.name)} key={page.name} url={page.url} label={
                  <Box display={'flex'} >
                    <Typography variant='h5' sx={{color: customTheme}} textAlign="center">{page.name}</Typography>
                    {!!open[page.name] ? (
                          <ExpandLessIcon
                            onClick={(e) => handleToggle(e, page.name)}
                            className={classes.expandIcon}
                            sx={{ fill :(page.submenu && page.submenu.length > 0) ? customTheme : colorCode}}
                          />
                        ) : (
                          <ExpandMoreIcon
                            onClick={(e) => handleToggle(e, page.name)}
                            className={classes.expandIcon}
                            sx={{ fill :(page.submenu && page.submenu.length > 0) ?  customTheme : colorCode}}
                          />
                        )}
                  </Box>
                  }>
                </StyledTab>
              ))}
            </StyledTabs>
            <Popper 
            sx={{flexGrow: 1, marginTop: '64px'}}
            // disablePortal={true}
            className={classes.popover}
            id={selectedMenu && open[selectedMenu] && Boolean(anchorEl) ? 'transition-popper' : undefined } 
            open={selectedMenu && open[selectedMenu] ? open[selectedMenu] : false} anchorEl={anchorEl} 
            transition>
              <Content />
            </Popper>
          </Box>
          </ClickAwayListener>}

          {!!currentUser &&<Box sx={{ flexGrow: 1, ml:10, alignSelf: 'flex-end', display: { xs: 'none', md: 'flex' } }}>
            <StyledTabs
              m={2}
              value={value}
              defaultValue={0}
              onChange={handleChange}
              aria-label="styled tabs example"
            >
              <StyledTab url={`${process.env.PUBLIC_URL}/`} label="Home"  />
              <StyledTab url={`${process.env.PUBLIC_URL}/pricing`} label="Plans and Pricing" />
              
            </StyledTabs>
          </Box>}

          {!!currentUser &&<Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
            <Box display='flex' flexDirection='row' alignContent='center' justifyContent='space-between'>
            <Button
              style={{ textTransform: 'none', marginRight: '10px', borderColor: customTheme, color: customTheme,
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: colorCode
                },
              }}
              // component={RouterLink}
              // to={`${process.env.PUBLIC_URL}/login?checkout=false`}
              variant="outlined"
            >
              <Typography color={customTheme} variant="h5">
              {'Upgrade'}
              </Typography>
            </Button>
            <Button
              style={{ textTransform: 'none', marginRight: '10px', borderColor: customTheme, backgroundColor: customTheme,
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: colorCode
                },
              }}
              component={RouterLink}
              to={`${process.env.PUBLIC_URL}/app/create`}
              variant="contained"
            >
              <Typography color={'#333E48'} variant="h5">
              {'Create survey'}
              </Typography>
            </Button>
          
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="Remy Sharp" src='' sx={{  color: "#fff" }}>{currentUser ? currentUser.name.charAt(0) : ''}</Avatar>
            </IconButton>
            <Menu
              sx={{ mt: '48px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((s,i) => (
                <MenuItem key={s.name} component={CustomRouterLink} to={s.url} onClick={s.function}>
                  <Typography textAlign="center">{s.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          </Box>}
          {!currentUser && <Box sx={{display: { xs: 'none', md: 'flex' }}} flexDirection='row' alignContent='center' justifyContent='space-between'>
          <Button
            style={{ textTransform: 'none', borderColor: customTheme, color: customTheme, backgroundColor: colorCode}}
            component={RouterLink}
            to={`${process.env.PUBLIC_URL}/login?checkout=false`}
            variant="text"
          >
            <Typography color={customTheme} variant="h5">
            {'Log in'}
            </Typography>
          </Button>
          <Button
            style={{ textTransform: 'none', borderColor: customTheme, color: customTheme, backgroundColor: colorCode}}
            component={RouterLink}
            to={`${process.env.PUBLIC_URL}/signUp?checkout=false`}
            variant="outlined"
          >
            <Typography color={customTheme} variant="h5">
            {'Sign Up Free'}
            </Typography>
          </Button>
        </Box>}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
