import React from 'react';

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src={(props && props.type && props.type === "blue" ) ? "../../static/logo.png" : "../../static/logo-white.png"}
      {...props}
      height={!!props && props.height ? props.height : (props && props.type && props.type === "blue" ) ? '80em':  '50em'}
    />
  );
};

export default Logo;
