import React from 'react';

const Icon = (props) => {
  return (
    <img
      alt={props.type}
      src={(props && props.type && props.type === "google" ) ? "static/icons/google.png" : "static/icons/facebook.png"}
      {...props}
      width={'30em'}
    />
  );
};

export default Icon;
