import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate, useLocation, NavLink as RouterLink } from 'react-router-dom';
import { Modal, Card, CardContent, CardHeader, CardActions, Divider, Box, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TopBar from './TopBar';
import theme from '../../theme';
import AuthService from '../../services/auth.service';
import CustomizedSnackbar from '../../components/CustomizedSnackbar';
import Footer from './Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    overscrollBehaviorY: 'contain'
  },
  card: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 57,
    [theme.breakpoints.up('lg')]: {
      paddingTop: 64
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'auto',
    overscrollBehavior: 'contain',
    flexDirection: 'column'
  },
  footer: {
    position:'relative',
    left:0,
    bottom:0,
    right:0
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end'
  }
}));

const MainLayout = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isExtended, setIsExtended] = useState(false);
  const [isDesktopNavOpen, setDesktopNavOpen] = useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [timeoutId, setTimeoutId] = React.useState();
  const [error, setError] = React.useState(false);

  const handleClose = () => setModalOpen(false);
  const [secondsPassed, setSecondsPassed] = React.useState(0);
  
  let dir = localStorage.getItem("admindir");
  document.querySelector("#root").setAttribute("admindir", dir);
  theme.direction = dir;

  const handleRefresh = () => {
    let token = AuthService.getCurrentUser().refreshToken
    AuthService.refreshToken(token).then(
      (response) => {
        handleClose();
        setIsExtended(true);
        navigate(`${location.pathname}`, { replace: true });
        localStorage.setItem('expired', false);
        clearTimeout(timeoutId);
        setTimer();
        setError(false)
      },
      error => {
          setError(true);
          console.log(JSON.stringify(error));
      }
  );
  }

  const setTimer = () => {
    const startDateTime = new Date().setSeconds(0);
    let exp = localStorage.getItem("exp");
    const endDateTime = new Date(exp).setSeconds(0);
    const differenceInMilliseconds = endDateTime - startDateTime;
    if(!isExtended && (startDateTime < endDateTime)) {
      setTimeout(() => {  console.log("Session going to expire..."); setModalOpen(true) }, differenceInMilliseconds-120000);
      let timeoutId = setTimeout(() => {
        localStorage.setItem('expired', true);
        navigate(`${process.env.PUBLIC_URL}/logout`, { replace: true });
      }, differenceInMilliseconds);
      setTimeoutId(timeoutId);
    }
  }

  useEffect(() => {
    setTimer();
    // eslint-disable-next-line
  },[]);
  const startTime = 60 * 2;
  const timeLeft = startTime - (secondsPassed % startTime);

  const minuteCount = ~~(timeLeft / 60);
  const secondsCount = timeLeft % 60;
  const countdown =  `${minuteCount}:${secondsCount.toLocaleString("en-US", {
    minimumIntegerDigits: 2
  })}`;

  useEffect(() => {
    if(!!modalOpen){
      const timer = setInterval(() => {
        setSecondsPassed(sp => sp + 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [modalOpen]);

  return (
    <div className={classes.root}>
      <TopBar isDesktopNavOpen={isDesktopNavOpen} setDesktopNavOpen={setDesktopNavOpen} setMobileNavOpen={setMobileNavOpen} isMobileNavOpen={isMobileNavOpen} onMobileNavOpen={() => setMobileNavOpen(!isMobileNavOpen)} onDesktopNavOpen={() => setDesktopNavOpen(!isDesktopNavOpen)} />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div>
            <Outlet />
            <Modal
              className={classes.modal} 
              open={modalOpen}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
                <Card className={classes.card} >            
                  <CardHeader
                    title={('session_timeout_title')}
                  />
                  <Divider />
                  <CardContent className={classes.content}>
                    <Typography variant="subtitle2" color='textPrimary' align="left">
                      {('session_timeout_message')}
                    </Typography>
                  </CardContent>
                  <Divider />
                  <CardActions className={classes.actions}>
                    <Box my={1}>
                      <Button
                        color='secondary'
                        fullWidth
                        size="small"
                        type="submit"
                        variant="contained"
                        onClick={handleRefresh}
                        style={{textTransform: "none"}}
                      >
                        {`${('yes')} (${countdown})`}
                      </Button>
                    </Box>
                    <Box my={1}>
                      <Button
                        color='secondary'
                        fullWidth
                        size="small"
                        type="submit"
                        variant="contained"
                        style={{textTransform: "none"}}
                        component={RouterLink}
                        to={`${process.env.PUBLIC_URL}/logout`}
                      >
                        {('logout')}
                      </Button>
                    </Box>
                  </CardActions>
                  </Card>
            </Modal>
            <CustomizedSnackbar
              open={modalOpen && !!error}
              handleClose={handleClose}
              severity={'error'}
              msg={'Unable to extend the session. Please login.'}
              autoHideDuration={5000}
            />
          </div>
          <Footer className={classes.footer} />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
