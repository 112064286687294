import React from "react";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@mui/styles";
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import GlobalStyles from './components/GlobalStyles';
// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function withRoot(Component) {
    function WithRoot(props) {
        return (
            <ThemeProvider theme={theme}>
                <StylesProvider jss={jss}>
                    <GlobalStyles />
                    <Component {...props} />
                </StylesProvider>
            </ThemeProvider>
        );
    }

    return WithRoot;
}

export default withRoot;
