import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthService from '../services/auth.service';

export const PrivateRoute = ({ component: Component, authorities, ...rest }) => {
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
        // not logged in so redirect to login page with the return url
        return (<Navigate to={`${process.env.PUBLIC_URL}/pricing`} replace={true} />);
    }
    // check if route is restricted by privileges
    if (AuthService.hasAuthority(authorities) === false) {
        // user is not authorised so redirect to logout page
        console.log('logout')
        return (<Navigate to={`${process.env.PUBLIC_URL}/`} replace={true} />);
    }
    return (<Component {...rest} />);
}