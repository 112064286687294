import React, {forwardRef} from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { makeStyles, styled } from '@mui/styles';
import Logo from '../../components/Logo';
import Page from '../../components/Page';
import { CheckOutContextProvider } from "./context";
import CheckOut from './components/CheckOut';
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  ListItem,
  Button,  
  alpha,
  colors,
  Toolbar
} from '@mui/material';
import AuthService from '../../services/auth.service';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    height: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(1)
  },
  topbar: {
    padding: theme.spacing(0,5)
  },
  buttonLeaf: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      fontWeight: theme.typography.fontWeightMedium
    }
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const CheckOutView = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const currentUser = AuthService.getCurrentUser();
  const open = Boolean(anchorEl);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get('id')

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    AuthService.logout();
  }
  return (
    <Page
    className={classes.root}
    title={`Visible Stars CX - Subscription purchase`}
  >
    <Toolbar className={classes.toolbar}>
      <RouterLink to={`${process.env.PUBLIC_URL}/`}>
        <Logo style={{height: '50px'}} type='blue' />
      </RouterLink>
      <Box flexGrow={1} />
      {!!currentUser && <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Button
        type='text'
          onClick={handleClick}
          size="small"
          style={{ ml: 2, textTransform: 'none', backgroundColor: '#FFFFFF',
            "&:hover": {
              cursor: "pointer",
              backgroundColor: '#FFFFFF'
            }
           }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          endIcon={
          <ArrowDropDownIcon fontSize='medium' sx={{color: 'black'}}/>
          }
        >
          <Typography color={'priamry'} variant='h6'>{!!currentUser && currentUser.email ? currentUser.email : 'mounica@dd.com'}</Typography>
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose} disableRipple>
            <ListItem
            disableGutters
            className={classes.buttonLeaf}
            onClick={handleLogout}
            component={CustomRouterLink}
            to={`${process.env.PUBLIC_URL}/`}
          >
            Logout
          </ListItem>
          </MenuItem>
        </StyledMenu>
      </Box>}
    </Toolbar>
    <CheckOutContextProvider id={id}>
      <CheckOut />
    </CheckOutContextProvider>
  </Page>
  );
};

export default CheckOutView;
