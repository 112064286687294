import axios from "axios";
import { handleError } from '../helpers/handle-error';
import { Base64 } from 'js-base64';
import { Authority } from "../Authority";
import { jwtDecode }  from 'jwt-decode';

const API_URL = process.env.REACT_APP_API_BASE_URL + "/admin-api/auth";


class AuthService {

    login(username, passwd) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        var password = Base64.encode(passwd);

        let config = {
            cancelToken: source.token,
            username,
            password
        }

        try {
            return axios.post(API_URL + "/signin", config).then(response => {
                if (response.data.accessToken) {
                    const t = jwtDecode(response.data.accessToken);
                    const endDateTime = new Date(0);
                    endDateTime.setUTCSeconds(t.exp);
                    localStorage.setItem('exp', endDateTime);
                    localStorage.setItem('authorized', true);
                    localStorage.setItem('currentUser', JSON.stringify(response.data));
                }
                return response.data;
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log("cancelled");
            } else {
                return handleError(error);
            }
        }

        return () => {
            source.cancel();
        };
    }

    refreshToken (token) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        let config = {
            refreshToken : token
        }
        try {
            return axios.post(API_URL + "/refreshtoken", config).then(response => {
                if (response.data.accessToken) {
                    let _currentUser = this.getCurrentUser();
                    const t = jwtDecode(response.data.accessToken);
                    const endDateTime = new Date(0);
                    endDateTime.setUTCSeconds(t.exp);
                    localStorage.setItem('exp', endDateTime);
                    _currentUser.accessToken = response.data.accessToken
                    _currentUser.refreshToken = response.data.refreshToken
                    localStorage.setItem('authorized', true);
                    localStorage.setItem('currentUser', JSON.stringify(_currentUser));
                }
                return response.data;
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log("cancelled");
            } else {
                return handleError(error);
            }
        }

        return () => {
            source.cancel();
        };
    }

    logout() {
        // remove user from local storage to log user out
        console.log("logout...")
        localStorage.removeItem('currentUser');
    }

    getCurrentUser() {
        return JSON.parse(localStorage.getItem('currentUser'));
    }

    hasAuthority(authorities) {
        const currentUser = this.getCurrentUser();
        if (currentUser && currentUser.authorities) {
            // general
            if (authorities.includes(Authority.general)) {
                return true;
            }
            // check user has authority 
            if (currentUser.authorities.some(authority => authorities.includes(authority))) {
                return true;
            }
        }
        return false;
    }
}

export default new AuthService();