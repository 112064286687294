import React from 'react';
import Page from '../../../components/Page';
import {makeStyles} from '@mui/styles';
import SideBar from './SideBar';
const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        padding: theme.spacing(0)
    }
}));

const Design = () => {
    const classes = useStyles();
    return (
        <Page title="Design" className={classes.root}>
            <SideBar />
        </Page >
    );
};
export default Design;
