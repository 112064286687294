import React from 'react';
import Page from '../../../components/Page';
import {makeStyles} from '@mui/styles';
import Toolbar from './Toolbar';
import Design from './Design';
import PropTypes from 'prop-types';
import {
    Tab,
    AppBar,
    Tabs,
    Box,
    Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-flexContainer': {
        display:'flex', alignItems: 'center'
    },
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      // maxWidth: 40,
      width: '100%',
      backgroundColor: '#635ee7',
    },
  });

const StyledTab = styled((props) => <Tab 
// component={RouterLink}
//   to={`${process.env.PUBLIC_URL}/pricing`}
  disableRipple {...props} />)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    fontSize: theme.typography.h6.fontSize,
    fontWeight: '600',
    marginRight: theme.spacing(1),
    color: 'primary',
    '&.Mui-selected': {
      color: 'primary',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }),
);

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2)
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: theme.spacing(4),
        }
    }
}));

const Survey = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Page title="Survey" className={classes.root}>
            <Toolbar />
            <Divider sx={{borderBottom: '1px solid #D0D2D3'}} />
            <AppBar style={{backgroundColor: '#FFFF',borderBottom: '1px solid #D0D2D3', boxShadow: 'none'}} position="static" color="default">
                <StyledTabs
                    m={2}
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                >
                    <StyledTab label="Summary" {...a11yProps(0)} />
                    <ArrowRightAltOutlinedIcon color="disabled" />
                    <StyledTab label="Design Survey" {...a11yProps(2)} />
                    <ArrowRightAltOutlinedIcon color="disabled" />
                    <StyledTab label="Collect Responses" {...a11yProps(4)} />
                    <ArrowRightAltOutlinedIcon color="disabled" />
                    <StyledTab label="Analyze Results" {...a11yProps(6)} />
                    <ArrowRightAltOutlinedIcon color="disabled" />
                    <StyledTab label="Present Results" {...a11yProps(8)} />
                </StyledTabs>
            </AppBar>
            <TabPanel value={value} index={2}>
                <Design />
            </TabPanel>
        </Page >
    );
};
export default Survey;
