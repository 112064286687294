import React from 'react';
import { Navigate } from 'react-router-dom';
import Login from './views/auth/Login';
import CheckOut from './views/auth/CheckOutView';
import { PrivateRoute } from './components/PrivateRoute';
import { Authority } from './Authority';
import NotFoundView from './views/errors/NotFoundView';
import HomeView from './views/home/HomeView';
import PricingView from './views/pricing/PricingView';
import MainLayout from './layouts/MainLayout';
import SurveyView from './views/survey/SurveyView';
import CreateView from './views/survey/components/CreateView';
import DesignSurveyView from './views/design/DesignSurveyView';

const routes = [
  {
    path: 'checkout',
    element: <PrivateRoute authorities={[Authority.general]} component={CheckOut} />
  },
  {
    path: 'app',
    element: <PrivateRoute authorities={[Authority.general]} component={MainLayout} />,
    children: [
      { path: 'home', element: <PrivateRoute authorities={[Authority.general]} component={SurveyView} /> },
      { path: 'create', element: <PrivateRoute authorities={[Authority.general]} component={CreateView} /> },
    ]
  },
  {
    path: 'app/surveys',
    element: <PrivateRoute authorities={[Authority.general]} component={MainLayout} />,
    children: [
      { path: 'edit', element: <PrivateRoute authorities={[Authority.general]} component={DesignSurveyView} /> },
    ]
  },
  {
    path: 'login',
    element:  <Login login={true} />
  },
  {
    path: 'signUp',
    element:  <Login login={false} />
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <HomeView /> },
      { path: 'pricing', element: <PricingView />},
      { path: '*', element: <Navigate to="404" /> }
    ]
  }
];

export default routes;
