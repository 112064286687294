import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Card,
  CardContent,
  Alert,
  CardHeader,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Grid,
  Stack
} from '@mui/material';
import Page from '../../components/Page';
import { makeStyles } from '@mui/styles';
import Logo from '../../components/Logo';
import { useGoogleLogin } from '@react-oauth/google';
import { styled } from '@mui/material/styles';
import KeyIcon from '@mui/icons-material/Key';
import Icon from '../../components/Icon';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    height: '100%',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3)
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  card: {
    border: '1px solid #EDEEEE',
    boxShadow: 'none',
    height: '100%',
    width: '100%',
    textAlign: 'left'
  },
  social: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  app: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    padding: theme.spacing(0, 2)
  }
}));

const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
  }));
  
  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#221bff',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&::before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#0000c6',
    },
  });
  
  function BpCheckbox(props) {
    return (
      <Checkbox
        sx={{
          '&:hover': { bgcolor: 'transparent' },
        }}
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        inputProps={{ 'aria-label': 'Checkbox demo' }}
        {...props}
      />
    );
  }

const Login = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loginView, setLoginView] = useState(false);
  const [firstPage, setFirstPage] = useState(true);
  const [email, setEmail] = useState('');

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const checkout = query.get('checkout')

  useEffect(() => {
    setLoginView(props && props.login)
  }, [props]);

  const handleLogin = (val) => {
    axios
      .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${val.access_token}`, {
          headers: {
              Authorization: `Bearer ${val.access_token}`,
              Accept: 'application/json'
          }
      })
      .then((res) => {
          let currentUser = res.data;
          currentUser.authorities = ['General', 'Manage Settings'];
          currentUser.access_token = val.access_token;
          localStorage.setItem('authorized', true);
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          if(!!checkout) {
            navigate(`${process.env.PUBLIC_URL}/app/home`, { replace: true });
          } else {
            navigate(`${process.env.PUBLIC_URL}/checkout`, { replace: true });
          }
      })
      .catch((err) => console.log(err));
    }

  const googleLogin = useGoogleLogin({
    onSuccess: responseMessage => {
      handleLogin(responseMessage)
    },
    onError: errorMessage => {
      console.log(errorMessage);
    }
  });

  let customValidationSchema = Yup.object().shape({
    password: Yup.string().min(8, "Password must have at least 8 characters")
    .required("Password is required"),
    confirmPassword: Yup
      .string()
      .oneOf([Yup.ref('password')], 'Passwords must match'),
  })

  if(!!loginView) {
    customValidationSchema = Yup.object().shape({
      password: Yup.string().required("Password is required"),
    })
  }
 
  return (
    <Page
      className={classes.root}
      title={`Visible Stars CX - ${!!loginView ? 'Log in' : 'Create an account'}`}
    >
      <Box alignItems={'center'} display='flex' flexDirection='column' justifyContent = 'space-evenly'>
        <Logo type='blue'/>
        <Container maxWidth="sm">
          {!!firstPage && <Formik
          initialValues={{
              email: email,
              terms: false,
              promotions: false
          }}
          validationSchema={Yup.object().shape({
              email: Yup.string().required("Email is required").email().matches(
                /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                "Enter a valid email address"
              ),
          })}
          onSubmit={(values, actions) => {
            console.log(values);
            setEmail(values.email);
            setFirstPage(false);
          }}
          >
          {({
              errors,
              status,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
          }) => (
          <form onSubmit={handleSubmit}>
            <Box px={5} pt={3}>
              <Card className={classes.card}>
                  <CardHeader
                  title={
                  <Typography style={{textAlign: 'right'}} variant='h6'>{loginView === true ? 'Don’t have an account?' : 'Already have an account?' }
                      <Link to={loginView === true ? `${process.env.PUBLIC_URL}/signUp` : `${process.env.PUBLIC_URL}/login`} style={{color:'#007faa'}} variant="contained" underline='none'>
                          {loginView === true ? ' Sign up' : ' Log in'}
                      </Link>
                  </Typography>
                  }></CardHeader>
                  <CardContent>
                    <Box className={classes.content}>
                      <Box mb={3}>
                        <Typography
                        gutterBottom
                        color="textPrimary"
                        variant="h2"
                        >
                        {!!loginView ? 'Log in' : 'Create an account'}
                        </Typography>
                      </Box>
                      {status && status.error &&
                          <Box my={2}>
                          <Alert variant="outlined" severity="error">
                              {status.error}
                          </Alert>
                          </Box>
                      }
                      <Typography variant='h6'>Email address</Typography>
                        <TextField
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          fullWidth
                          style={{marginTop: '5px'}}
                          margin="normal"
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          type="text"
                          value={values.email}
                          variant="outlined"
                          color='text'
                        />
                      {!!loginView  && <Typography variant='h6'> 
                          <Link to={'#'} style={{color:'#007faa'}} variant="contained" underline='none'>
                              {'Forgot email?'}
                          </Link>
                      </Typography>}
                      {!loginView && <FormGroup>
                          <FormControlLabel sx={{marginBottom: '4px'}} onChange={handleChange} name="terms" value={values.terms} control={<BpCheckbox />} label={<Typography variant='body2'>You agree to the Terms of Use and Privacy Notice.</Typography>} />
                          <FormControlLabel onChange={handleChange} name="promotions" value={values.promotions} control={<BpCheckbox />} label={<Typography variant='body2'>You agree to receive product news and special promotions via email.</Typography>} />
                      </FormGroup>}
                      <Box my={2}>
                          <Button
                          color='secondary'
                          disabled={!loginView ? (!(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i).test(values.email) || !values.terms) : (!(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i).test(values.email))}
                          fullWidth
                          size="large"
                          variant="contained"
                          type='submit'
                          >
                            Next
                          </Button>
                      </Box>
                    </Box>
                  </CardContent>
                  <CardContent style={{borderTop: "1px solid lightgrey"}}>
                  <Box className={classes.content}>
                    <Typography
                      mb={1}
                      color="textPrimary"
                      variant="h6"
                      >
                      {!!loginView ? 'Or Log in with' : 'Or Sign up with'}
                    </Typography>
                    <Grid container rowSpacing={3} columns={2}>
                      {!!loginView && <Grid item xs={12}>
                        <Button size='large' style={{ width: '100%', border: '1px solid #333E48', textTransform: 'none'}}>
                          <KeyIcon sx={{color: 'black'}}/>
                          <Typography sx={{marginLeft: '10px'}} variant='h5'>SSO</Typography>
                        </Button>
                      </Grid>}
                      <Grid item xs={12}>
                        <Button size='large' style={{ width: '100%', border: '1px solid #333E48', textTransform: 'none'}} onClick={() => googleLogin()}>
                          <Icon type="google" />
                          <Typography sx={{marginLeft: '10px'}} variant='h5'>Google</Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </form>
          )}
          </Formik>}
          {!!email && !firstPage && <Formik
            initialValues={{
                password: '',
                confirmPassword: ''
            }}
            validationSchema={customValidationSchema}
            onSubmit={(values, actions) => {
              navigate(`${process.env.PUBLIC_URL}/checkout`, { replace: true });
            }}
            >
            {({
                errors,
                status,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
            }) => (
            <form onSubmit={handleSubmit}>
              <Box px={5} pt={3}>
                <Card className={classes.card}>
                  <CardHeader title={
                    <Typography variant='h6'>
                      <Link onClick={() => { setFirstPage(true);}} style={{color:'#007faa'}} variant="contained" underline='none'>
                        <Stack alignItems="center" direction="row" gap={0}>
                          <KeyboardArrowLeft fontSize='medium' />
                          Back
                        </Stack>
                      </Link>
                    </Typography>
                    }>
                    </CardHeader>
                    <CardContent>
                      <Box className={classes.content}>
                        <Box mb={3}>
                          <Typography
                          gutterBottom
                          color="textPrimary"
                          variant="h2"
                          >
                          {!!loginView ? 'Enter password' : 'Create password'}
                          </Typography>
                        </Box>
                        {status && status.error &&
                            <Box my={2}>
                            <Alert variant="outlined" severity="error">
                                {status.error}
                            </Alert>
                            </Box>
                        }
                          <Box mb={3}>
                            <Typography
                            gutterBottom
                            color="textPrimary"
                            variant="h5"
                            >
                            {email}
                            </Typography>
                          </Box>
                          <Typography variant='h6'>Enter a password</Typography>
                          <TextField
                            error={Boolean(touched.password && errors.password)}
                            helperText={touched.password && errors.password}
                            fullWidth
                            style={{marginTop: '5px'}}
                            margin="normal"
                            name="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="password"
                            value={values.password}
                            variant="outlined"
                            color='text'
                          />
                          {!loginView  && <Typography gutterBottom variant='body2'>Enter at least 8 characters. Don’t use common words, names, or sequential or repeated characters.</Typography>}
                          {!loginView  && <Typography variant='h6'>Confirm password</Typography>}
                          {!loginView  && <TextField
                            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                            helperText={touched.confirmPassword && errors.confirmPassword}
                            fullWidth
                            style={{marginTop: '5px'}}
                            margin="normal"
                            name="confirmPassword"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type="password"
                            value={values.confirmPassword}
                            variant="outlined"
                            color='text'
                          />}
                        {!!loginView  && <Typography variant='h6'> 
                            <Link to={'#'} style={{color:'#007faa'}} variant="contained" underline='none'>
                                {'Forgot password?'}
                            </Link>
                        </Typography>}
                        <Box my={2}>
                            <Button
                            color='secondary'
                            disabled={(!!loginView) ? (!values.password) : (values.password  !== values.confirmPassword)}
                            fullWidth
                            size="large"
                            type='submit'
                            variant="contained"
                            >
                              {loginView === false ? 'Create account' : 'Log in'}
                            </Button>
                        </Box>
                      </Box>
                    </CardContent>
                </Card>
              </Box>
            </form>
            )}
          </Formik>}
        </Container>
      </Box>
    </Page>
  );
};

export default Login;
