import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  Tabs,
  Tab
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Pricing from './Pricing';
import ContactUs from './ContactUs';
import Page from '../../../components/Page';
import { makeStyles } from '@mui/styles';
import theme from '../../../theme';
import { useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    // maxWidth: 40,
    width: '100%',
    backgroundColor: '#635ee7',
  },
});

const StyledTab = styled((props) => <Tab component={RouterLink}
  to={`${process.env.PUBLIC_URL}/pricing`}
  disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontSize: theme.typography.h5.fontSize,
    marginRight: theme.spacing(1),
    color: 'primary',
    '&.Mui-selected': {
      color: 'primary',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }),
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const individuals = [
  {
    id:5,
    name: 'Individual',
    title: 'Premier Annual',
    subTitle: 'SAR 325/ month',
    desc: 'SAR 3,900 billed annually',
    color: '#05467e',
    list: [
      'Unlimited number of surveys',
      'Unlimited questions per survey',
      '40,000 responses per year**',
      '24/7 customer support via email',
      'Quizzes with custom feedback',
      'Data exports (CSV, PDF, PPT, XLS)',
      'Skip logic, question & answer piping',
      'Text analysis',
      'Custom logo, colors, and survey URL',
      'Advanced data exports (SPSS)',
      'A/B testing, randomization, quotas',
      'Custom variables',
      'Accept payments',
      'File upload',
      'VisibleStars industry benchmarks',
      'Multilingual surveys',
      'Click map data visualization',
      'Response Quality',
      'Crosstabs',
      'Unlimited filter and compare rules, data trends',
      'Advanced branching & piping, block randomization',
      'White label surveys'
    ]
  },
  {
    id:4,
    name: 'Individual',
    title: 'Advantage Annual',
    subTitle: 'SAR 125/ month',
    desc: 'SAR 1,500 billed annually',
    ribbon: 'Best Value',
    color: '#007faa',
    list: [
      'Unlimited number of surveys',
      'Unlimited questions per survey',
      '15,000 responses per year**',
      '24/7 customer support via email',
      'Quizzes with custom feedback',
      'Data exports (CSV, PDF, PPT, XLS)',
      'Skip logic, question & answer piping',
      'Text analysis & statistical significance',
      'Custom logo, colors, and survey URL',
      'Advanced data exports (SPSS)',
      'A/B testing, randomization, quotas',
      'Custom variables',
      'Accept payments',
      'File upload',
      'VisibleStars industry benchmarks',
      'Multilingual surveys'
    ]
  },
  {
    id:3,
    name: 'Individual',
    title: 'Standard Monthly',
    subTitle: 'SAR 139/ month',
    desc: '',
    color: '#00bf6f',
    list: [
    'Unlimited number of surveys',
    'Unlimited questions per survey',
    '1,000 responses per month**',
    '24/7 customer support via email',
    'Quizzes with custom feedback',
    'Data exports (CSV, PDF, PPT, XLS)',
    'Skip logic only',
    'Text analysis',
    ]
  }
]

const teams = [
  {
    id: 1,
    name: 'Team',
    color: '#007faa',
    title: 'Team Advantage',
    subTitle: 'SAR 95/ user / month',
    ribbon: 'Save 24% *',
    desc: 'Starting at 3 users, billed annually',
    list: [
    'Survey sharing with fine control over who can view and edit',
    'Gather comments all in one place',
    'Let team members analyze, filter, and export results',
    'Notify others when you get new responses',
    'Shared asset library for on-brand surveys',
    'Add Contributor Seats',
    'Add or reassign accounts at any time',
    'Free integrations with popular collaboration apps',
    '50,000 responses per year**',
    '24/7 expedited email support',
    'Quizzes with custom feedback',
    'Custom logo, colors, and survey URL',
    'Skip logic, question & answer piping',
    'All data exports (CSV, XLS, PPT, PDF, SPSS)',
    'Accept payments',
    'Text analysis & statistical significance',
    'Advanced analyze features',
    'VisibleStars industry benchmarks',
    'Multilingual surveys'
    ]
  },
  {
    id: 2,
    name: 'Team',
    color: '#671e75',
    title: 'Team Premier',
    subTitle: 'SAR 245/ user / month',
    desc: 'Starting at 3 users, billed annually',
    ribbon: 'Save 24% *',
    list: [
      'Survey sharing with fine control over who can view and edit',
      'Gather comments all in one place',
      'Let team members analyze, filter, and export results',
      'Notify others when you get new responses',
      'Shared asset library for on-brand surveys',
      'Add Contributor Seats',
      'Add or reassign accounts at any time',
      'Free integrations with popular collaboration apps',
      '100,000 responses per year**',
      'Phone support and 24/7 email support',
      'Quizzes with custom feedback',
      'Custom logo, colors, and survey URL',
      'Skip logic, question & answer piping',
      'All data exports (CSV, XLS, PPT, PDF, SPSS)',
      'Accept payments',
      'Text analysis & statistical significance',
      'Advanced analyze features',
      'VisibleStars industry benchmarks',
      'Multilingual surveys',
      'Click map data visualization',
      'Response Quality',
      'Crosstabs',
      'Unlimited filter and compare rules, data trends',
      'Advanced survey logic tools',
      'Block randomization',
      'White label surveys',
      'Survey completion redirect',
      'Remove VisibleStars footer'
    ]
  },
  {
    name: 'Team',
    color: '#05467e',
    title: 'Enterprise',
    subTitle: '',
    desc: 'Powerful admin tools, integrations, and collaboration features for your organization.',
    list: [
      'Survey sharing with fine control over who can view and edit',
      'Gather comments all in one place',
      'Let team members analyze, filter, and export results',
      'Notify others when you get new responses',
      'Shared asset library for on-brand surveys',
      'Add Contributor Seats',
      'Add or reassign accounts at any time',
      'Free integrations with popular collaboration apps',
      'Unlimited surveys and questions',
      'Phone support and 24/7 email support',
      'Quizzes with custom feedback',
      'Custom logo, colors, and survey URL',
      'Skip logic, question & answer piping',
      'All data exports (CSV, XLS, PPT, PDF, SPSS)',
      'Accept payments',
      'Text analysis & statistical significance',
      'Advanced analyze features',
      'VisibleStars industry benchmarks',
      'Multilingual surveys',
      'Click map data visualization',
      'Response Quality',
      'Crosstabs',
      'Unlimited filter and compare rules, data trends',
      'Advanced survey logic tools',
      'Block randomization',
      'White label surveys',
      'Survey completion redirect',
      'Remove VisibleStars footer',
      'Unlimited API access***',
      'Flexible plan types for multiple users',
      'HIPAA-compliant features***',
      'Customization and branding',
      'Integrations with Salesforce, Marketo, Eloqua, and Tableau***',
      'Admin dashboard and user management',
      'Account control and migration',
      'Enhanced governance and security features',
      'Single sign-on (SSO)',
      'Activity tracking through audit logs',
      'Dedicated customer success manager***'
    ]
  }
]

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(3)
  }
}))

const Plans = ({label}) => {
  const classes = useStyles();
  const location = useLocation();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if(location.hash === '#contactus'){
      setValue(2)
    }
  }, [location.hash]);
  return (
  <Page
    className={classes.root}
    title={`Visible Stars CX - Pricing`}
  >
    <Box alignItems={'center'} display='flex' flexDirection='column' justifyContent = 'space-between'>
      <Typography m={2} variant='h1'>Choose a plan that works for you</Typography>
      <StyledTabs
        m={2}
        value={value}
        defaultValue={0}
        onChange={handleChange}
        aria-label="styled tabs example"
      >
        <StyledTab label="Team Plans" />
        <StyledTab label="Individual Plans" />
        <StyledTab label="Enterprise" />
      </StyledTabs>
      <TabPanel value={value} index={0}>
          <Pricing label={label} data={teams} />
      </TabPanel>
      <TabPanel value={value} index={1}>
          <Pricing label={label} data={individuals} />
      </TabPanel>
      <TabPanel value={value} index={2}>
          <ContactUs />
      </TabPanel>
    </Box>
  </Page>
  );
};
export default Plans;
