import { createTheme } from '@mui/material/styles';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

const theme = createTheme({
  direction: 'ltr',
  palette: palette,
  typography: typography,
  overrides: overrides
});

export default theme;
